<template>
  <div id="app">
    <div class="center">
      <AppBox>
        <img alt="Marques Serviços Financeiros" src="./assets/logo.png" style="width: 80%; margin: 12px 0" />
      </AppBox>
      <AppBox theme="green" link="http://www.sinteg.com.br:8844/">
        Acesse seu Banco de Dados
      </AppBox>
      <AppBox link="tel:+554730584760" :icon="true">
        <AppIcon>
          <img alt="" src="./assets/phone.png" />
        </AppIcon>
        (47) 3058-4760
      </AppBox>
      <AppBox link="mailto:vendas@marques.srv.br" :icon="true">
        <AppIcon>
          <img alt="" src="./assets/email.png" />
        </AppIcon>
        vendas@marques.srv.br
      </AppBox>
      <AppBox link="https://goo.gl/maps/vHdApB9xmkmDNHwk6" :icon="true">
        <AppIcon>
          <img alt="" src="./assets/map.png" />
        </AppIcon>
        Rua José Marangoni, 330, Sala 01<br/>
        Vila Nova, Jaraguá do Sul / SC
      </AppBox>
      <AppBox theme="green" @click="toggleForm" :clickable="true">
        Envie-nos uma mensagem
      </AppBox>
      <transition name="fade">
        <AppBox v-if="contactForm">
          <AppContactForm></AppContactForm>
        </AppBox>
      </transition>
    </div>
  </div>
</template>

<script>
import AppBox from './components/AppBox.vue'
import AppIcon from './components/AppIcon.vue'
import AppContactForm from './components/AppContactForm.vue'

export default {
  name: 'App',
  components: {
    AppBox,
    AppIcon,
    AppContactForm
  },
  data() {
    return {
      contactForm: false
    }
  },
  methods: {
    toggleForm() {
      this.contactForm = !this.contactForm
    }
  }
}
</script>

<style lang="scss">
* {
  font-family: inherit;
  font-weight: inherit;
}

div {
  margin: 0 auto;
}

html, body {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    background: center repeat url('./assets/background.jpg');
    font-family: 'Lato', 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 40px 10px;
    max-width: 420px;
    min-height: 100vh;
  }
}

// fade

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
