<template>
  <a
    class="box"
    v-bind:class="{ 'green': theme === 'green', 'icon': icon, 'clickable': link || clickable, 'text-center': !icon }"
    target="_blank"
    :href="link"
    @click="action">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'AppBox',
  props: [
    'theme',
    'link',
    'icon',
    'clickable'
  ],
  methods: {
    action() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 20px;
  background: white;
  font-size: 1em;
  color: #4E575E;
  text-align: left;
  text-decoration: none;
  box-shadow: 3px 3px 1px 0 rgba(0,0,0,0.3);

  &.green {
    background: #618849;
    color: white;
  }

  &.icon {
    padding-left: 55px;
  }

  &.text-center {
    text-align: center;
  }

  &.clickable:hover {
    cursor: pointer;
    padding-top: 17px;
    padding-bottom: 13px;
    box-shadow: inset 3px 3px 1px 0 rgba(0,0,0,0.3);
  }

  & + .box {
    margin-top: 12px;
  }

  img {
    max-width: 100%;
  }
}
</style>
