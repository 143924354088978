<template>
  <span class="icon"><slot></slot></span>
</template>

<script>
export default {
  name: 'AppIcon'
}
</script>

<style scoped lang="scss">
.icon {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -8px;
  width: 24px;
  height: 24px;
  padding: 8px;
  margin: auto;
  background: #618849;
  box-shadow: 2px 2px 1px 0 rgba(0,0,0,0.3);

  img {
    max-width: 24px;
  }
}
</style>
