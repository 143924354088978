<template>
  <form name="contact-form" class="contact-form" method="post" @submit.prevent="submeterFormulario">
    <div class="field">
      <label for="nome">Nome Completo</label>
      <input type="text" name="nome" id="nome" v-model="nome" :disabled="enviado" />
    </div>
    <div class="field">
      <label for="email">E-mail</label>
      <input type="email" name="email" id="email" v-model="email" :disabled="enviado" />
    </div>
    <div class="field">
      <label for="telefone">Telefone</label>
      <input type="text" name="telefone" id="telefone" v-model="telefone" :disabled="enviado" />
    </div>
    <div class="field">
      <label for="assunto">Assunto</label>
      <input type="text" name="assunto" id="assunto" v-model="assunto" :disabled="enviado" />
    </div>
    <div class="field">
      <label for="mensagem">Mensagem</label>
      <textarea type="text" name="mensagem" id="mensagem" v-model="mensagem" :disabled="enviado"></textarea>
    </div>
    <div v-if="erros.length > 0" class="errors">
      <span v-for="error in erros" :key="error">{{ error }}</span>
    </div>
    <button v-if="!enviado" type="submit">Enviar</button>
    <div v-if="enviado" class="success">Mensagem enviada com sucesso!</div>
  </form>
</template>

<script>
export default {
  name: 'AppContactForm',
  data() {
    return {
      erros: [],
      nome: null,
      email: null,
      telefone: null,
      assunto: null,
      mensagem: null,
      enviado: false
    }
  },
  methods: {
    submeterFormulario() {
      this.erros = [];

      if (!this.nome) {
        this.erros.push('Nome não preenchido');
      }

      if (!this.email) {
        this.erros.push('E-mail não preenchido');
      }

      if (!this.telefone) {
        this.erros.push('Telefone não preenchido');
      }

      if (!this.assunto) {
        this.erros.push('Assunto não preenchido');
      }

      if (!this.mensagem) {
        this.erros.push('Mensagem não preenchida');
      }

      if (this.erros.length > 0) {
        return;
      }

      this.enviarEmail();
    },
    enviarEmail() {
      let formData = new FormData();
      formData.append('nome', this.nome);
      formData.append('email', this.email);
      formData.append('telefone', this.telefone);
      formData.append('assunto', this.assunto);
      formData.append('mensagem', this.mensagem);

      let headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json'
      };

      let currentObj = this;
      this.axios.post('/send-email.php', formData, { headers })
      .then(function(response) {
        if (response.status == 200 && response.data.success) {
          currentObj.enviado = true;
        } else {
          currentObj.erros = ['Ocorreu um erro ao enviar sua mensagem.', 'Tente novamente mais tarde']
        }
      })
      .catch(function(error) {
        currentObj.erros = ['Ocorreu um erro ao enviar sua mensagem.', 'Tente novamente mais tarde']
        console.error('Error sending email message', error);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.contact-form {
  display: flex;
  flex-direction: column;

  .field {
    width: 100%;
    margin-bottom: 10px;
    text-align: left;

    label,
    input,
    textarea {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }

    label {
      padding: 0 4px 4px 4px;
      font-size: .8em;
      color: #4E575E;
    }

    input,
    textarea {
      padding: 9px 12px;
      border: none;
      background: #e1e4e9;
      color: #4E575E;
      font-size: .85em;

      &:hover {
        background: #d5d9df;
      }
    }

    textarea {
      height: 84px;
      min-height: 84px;
      resize: vertical;
    }
  }

  .errors {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    background: rgb(124, 56, 56);
    margin-bottom: 10px;

    span {
      display: block;
      font-size: .85em;
      color: white;

      & + span {
        margin-top: 5px;
      }
    }
  }

  button,
  .success {
    padding: 15px;
    border: none;
    outline: none;
    margin-top: 5px;
    background: #618849;
    color: white;
    font-size: 1em;
  }

  button {
    cursor: pointer;

    &:hover {
      opacity: .85;
    }
  }
}
</style>
